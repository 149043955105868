
import React, { useEffect, useRef } from 'react';
import { cn } from '@/lib/utils';
import { useInView } from 'react-intersection-observer';
import useTypewriter from '@/hooks/useTypewriter';

const clients = [
  { id: '01', name: 'BT' },
  { id: '02', name: 'Honda' },
  { id: '03', name: 'Virgin Media' },
  { id: '04', name: 'Vodafone' },
  { id: '05', name: 'Royal Mail' },
  { id: '06', name: 'Barclays' },
  { id: '07', name: 'HSBC' },
  { id: '08', name: "Lloyd's of London" },
  { id: '09', name: 'Royal Canin' },
  { id: '10', name: 'The Co-operative' },
  { id: '11', name: "St. James's Place" },
  { id: '12', name: 'Print Power' }
];

const ClientsSection = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const clientRefs = useRef<(HTMLDivElement | null)[]>([]);

  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const text = "Partnership Status : : Active";
  const displayedText = useTypewriter(inView ? text : '', 30);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              entry.target.classList.add('active');
              observer.unobserve(entry.target);
            }, index * 100);
          }
        });
      },
      { threshold: 0.2 }
    );

    clientRefs.current.forEach((client) => {
      if (client) observer.observe(client);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section id="clients" className="py-16 md:py-32 bg-white relative overflow-hidden">
      {/* Technical Grid Underlay */}
      <div className="absolute inset-0 grid grid-cols-4 opacity-[0.02] pointer-events-none">
        {[...Array(8)].map((_, i) => (
          <div key={i} className="border-r border-black/20" />
        ))}
        {[...Array(6)].map((_, i) => (
          <div key={i} className="border-b border-black/20 col-span-4" />
        ))}
      </div>

      <div className="container-custom" ref={containerRef}>
        <div className="reveal max-w-6xl mx-auto">
          <p className="section-heading transform transition-all duration-300 hover:tracking-[0.3em] text-center">
            Client Registry
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 mt-12 mb-12">
            {clients.map((client, index) => (
              <div
                key={client.id}
                ref={el => clientRefs.current[index] = el}
                className={cn(
                  "opacity-0 transform translate-y-4 transition-all duration-500",
                  "group relative min-h-[100px]",
                  "border border-black/10 hover:border-black/30 transition-colors duration-300",
                  "active:opacity-100 active:translate-y-0"
                )}
              >
                {/* Client Name */}
                <div className="flex items-center justify-center h-full p-6">
                  <p className="text-base md:text-lg font-medium text-center tracking-wide transition-all duration-300 group-hover:tracking-wider text-black/80">
                    {client.name}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Partnership Status Text */}
          <div ref={ref} className="text-center">
            <div className="font-mono text-[10px] text-black/90 flex items-center justify-center gap-2">
              <span className="w-2 h-px bg-black/90" />
              <span className={cn(
                "text-black/90 transition-opacity duration-1500",
                displayedText.length === text.length && "animate-[pulse_3s_ease-in-out_infinite]"
              )}>{displayedText}</span>
              <span className={cn(
                "inline-block h-3 w-0.5 bg-black/90 ml-1",
                displayedText.length === text.length ? "animate-[pulse_3s_ease-in-out_infinite]" : "animate-blink"
              )} />
              <span className="w-2 h-px bg-black/90" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientsSection;

