
import { ArrowDown } from 'lucide-react';
import DataStreamText from '../ui/data-stream-text';

const HeroContent = () => {
  return (
    <div className="reveal container-custom text-center py-20 md:py-32">
      <p className="section-heading animate-fadeIn transform transition-all duration-300 hover:tracking-[0.3em]">
        Content & Strategy Leader
      </p>
      <h1 
        className="text-6xl md:text-7xl lg:text-8xl font-semibold leading-tight mb-8 text-black"
      >
        Stuart Knott
      </h1>
      <div className="max-w-3xl mx-auto mb-12">
        <DataStreamText
          text="More than twenty years turning complex ideas into compelling content for ambitious brands – and crafting narratives that move markets and minds"
          className="text-lg md:text-xl lg:text-2xl"
        />
      </div>
      <a
        href="#about"
        className="inline-flex items-center justify-center w-12 h-12 rounded-full border border-black/30 hover:border-black transition-colors animate-bounce"
      >
        <ArrowDown className="w-5 h-5 text-black" />
      </a>
    </div>
  );
};

export default HeroContent;
