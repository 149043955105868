
const Footer = () => {
  return (
    <footer className="bg-background py-8 mt-16 border-t">
      <div className="container-custom">
        <div className="text-sm text-gray-800 max-w-4xl mx-auto text-center space-y-2">
          <p className="tracking-wide transition-all duration-300 hover:tracking-wider">Based in Ely, Cambridgeshire</p>
          <p className="tracking-wide transition-all duration-300 hover:tracking-wider">Available for projects and consulting</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
