import React, { useEffect, useRef } from 'react';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import useRevealOnScroll from '@/hooks/useRevealOnScroll';
import { useInView } from 'react-intersection-observer';
import useTypewriter from '@/hooks/useTypewriter';

const awards = [
  {
    id: "01",
    x: "1",
    y: "1",
    year: "2021",
    ceremony: "CMA International Content Marketing Awards",
    title: "Campaign of the Year"
  },
  {
    id: "02",
    x: "1",
    y: "2",
    year: "2014",
    ceremony: "British Society of Magazine Editor Awards",
    title: "Editor of the Year, Branded Content – Business"
  },
  {
    id: "03",
    x: "2",
    y: "1",
    year: "2011",
    ceremony: "CMA International Content Marketing Awards",
    title: "Overall Editor of the Year"
  },
  {
    id: "04",
    x: "2",
    y: "2",
    year: "2008",
    ceremony: "British Society of Magazine Editor Awards",
    title: "Editor of the Year – Innovation & Brand-building"
  }
];

const AwardsSection = () => {
  const elementRef = useRevealOnScroll();
  const cardsRef = useRef<(HTMLDivElement | null)[]>([]);

  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const text = "Performance Metrics : : Verified";
  const displayedText = useTypewriter(inView ? text : '', 30);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              entry.target.classList.add('active');
              observer.unobserve(entry.target);
            }, index * 200);
          }
        });
      },
      { threshold: 0.2 }
    );

    cardsRef.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section id="awards" className="py-20 md:py-32 bg-black overflow-hidden">
      <div className="container-custom">
        <div ref={elementRef} className="reveal">
          <p className="section-heading text-white transform transition-all duration-300 hover:tracking-[0.3em]">
            Awards & Recognition
          </p>

          {/* Technical Grid Underlay */}
          <div className="absolute inset-0 grid grid-cols-2 gap-8 pointer-events-none opacity-10">
            {[...Array(20)].map((_, i) => (
              <React.Fragment key={i}>
                <div className="h-full border-r border-white/20" />
              </React.Fragment>
            ))}
            {[...Array(20)].map((_, i) => (
              <div key={i} className="w-full border-b border-white/20" />
            ))}
          </div>

          {/* Awards Grid */}
          <div className="relative grid grid-cols-1 md:grid-cols-2 gap-8 [&>*]:h-full mb-12">
            {awards.map((award, index) => (
              <div
                key={award.id}
                ref={el => cardsRef.current[index] = el}
                className={cn(
                  "opacity-0 transform translate-y-4 transition-all duration-500 h-full",
                  "active:opacity-100 active:translate-y-0",
                  "group"
                )}
              >
                <Card className="relative p-6 border-white/20 bg-transparent overflow-hidden h-full flex flex-col">
                  {/* Coordinate Marker */}
                  <div className="absolute top-3 right-3 text-[10px] font-mono text-white/40">
                    [{award.x}.{award.y}]
                  </div>

                  {/* Technical ID and Validation Line */}
                  <div className="absolute top-0 left-0 w-full h-px">
                    <div className="h-full bg-gradient-to-r from-white/0 via-white/20 to-white/0 transform -translate-x-full group-hover:translate-x-0 transition-transform duration-1000" />
                  </div>

                  <CardContent className="p-0 flex flex-col h-full">
                    {/* Technical Year Badge */}
                    <div className="mb-6 inline-flex items-center space-x-2">
                      <Badge 
                        className="bg-white text-black hover:bg-white/90 font-mono"
                        variant="secondary"
                      >
                        [{award.year}]
                      </Badge>
                      {/* Record ID */}
                      <span className="text-[10px] font-mono text-white/40">
                        REC_{award.id}
                      </span>
                    </div>

                    {/* Award Title with Fade Effect */}
                    <h3 className="text-xl font-semibold mb-2 text-white font-mono tracking-tight">
                      {award.title}
                    </h3>

                    {/* Ceremony Name with Scan Line */}
                    <div className="relative mt-auto">
                      <p className="text-white/70 font-mono text-sm">
                        {award.ceremony}
                      </p>
                      <div className="absolute -bottom-2 left-0 w-full h-px overflow-hidden">
                        <div className="w-full h-px bg-white/20 relative">
                          <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/40 to-transparent transform -translate-x-full group-hover:translate-x-0 transition-transform duration-1000" />
                        </div>
                      </div>
                    </div>

                    {/* Technical Corner Markers */}
                    <div className="absolute top-0 left-0 w-3 h-3">
                      <div className="absolute top-0 left-1.5 h-3 w-px bg-white/20" />
                      <div className="absolute top-1.5 left-0 h-px w-3 bg-white/20" />
                    </div>
                    <div className="absolute bottom-0 right-0 w-3 h-3">
                      <div className="absolute bottom-0 right-1.5 h-3 w-px bg-white/20" />
                      <div className="absolute bottom-1.5 right-0 h-px w-3 bg-white/20" />
                    </div>
                  </CardContent>
                </Card>

                {/* Connecting Lines */}
                {index < awards.length - 1 && (
                  <div className={cn(
                    "absolute hidden md:block",
                    index % 2 === 0 ? "top-1/2 right-0 w-8 h-px bg-white/10" : "",
                    index < 2 ? "bottom-0 left-1/2 w-px h-8 bg-white/10" : ""
                  )} />
                )}
              </div>
            ))}
          </div>

          {/* Performance Metrics Text */}
          <div ref={ref} className="text-center">
            <div className="font-mono text-[10px] text-white/90 flex items-center justify-center gap-2">
              <span className="w-2 h-px bg-white/90" />
              <span className={cn(
                "text-white/90 transition-opacity duration-1500",
                displayedText.length === text.length && "animate-[pulse_3s_ease-in-out_infinite]"
              )}>{displayedText}</span>
              <span className={cn(
                "inline-block h-3 w-0.5 bg-white/90 ml-1",
                displayedText.length === text.length ? "animate-[pulse_3s_ease-in-out_infinite]" : "animate-blink"
              )} />
              <span className="w-2 h-px bg-white/90" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AwardsSection;
