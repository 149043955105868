
import { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import { cn } from '@/lib/utils';

const Navigation = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navLinks = [
    { href: '#about', label: 'About', id: '01' },
    { href: '#philosophy', label: 'Philosophy', id: '02' },
    { href: '#expertise', label: 'Expertise', id: '03' },
    { href: '#projects', label: 'Projects', id: '04' },
    { href: '#contact', label: 'Contact', id: '05' }
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);

      // Update active section based on scroll position
      const sections = navLinks.map(link => link.href.substring(1));
      const currentSection = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      
      setActiveSection(currentSection || '');
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
    e.preventDefault();
    const element = document.querySelector(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setIsMenuOpen(false);
    }
  };

  return (
    <nav
      className={cn(
        "fixed top-0 w-full z-50 transition-all duration-300",
        isScrolled 
          ? "py-4 bg-white/80 backdrop-blur-md border-b border-black/5" 
          : "py-6"
      )}
    >
      <div className="container-custom">
        <div className="flex items-center justify-between lg:justify-center">
          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="lg:hidden p-2 hover:bg-black/5 rounded-md transition-colors"
          >
            {isMenuOpen ? <X size={20} /> : <Menu size={20} />}
          </button>

          {/* Navigation Links */}
          <div
            className={cn(
              "fixed lg:static inset-x-0 top-[65px] bg-white/80 backdrop-blur-md lg:backdrop-blur-none border-b lg:border-none",
              "transition-all duration-300 lg:block",
              isMenuOpen ? "block" : "hidden"
            )}
          >
            <ul className="flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-8 p-4 lg:p-0">
              {navLinks.map((link) => (
                <li key={link.href} className="w-full lg:w-auto">
                  <a
                    href={link.href}
                    onClick={(e) => scrollToSection(e, link.href)}
                    className={cn(
                      "group relative flex items-center justify-center lg:justify-start py-2 px-4 lg:px-0",
                      "font-mono text-sm tracking-wide transition-colors duration-300",
                      "hover:text-black/80"
                    )}
                  >
                    {/* Technical ID */}
                    <span className="mr-2 text-[10px] text-black/40 font-mono">
                      [{link.id}]
                    </span>
                    
                    {/* Link Text */}
                    <span className="relative">
                      {link.label}
                      
                      {/* Active Indicator Line */}
                      <span
                        className={cn(
                          "absolute -bottom-0.5 left-0 w-full h-px bg-black transition-all duration-300",
                          "origin-left",
                          activeSection === link.href.substring(1)
                            ? "scale-x-100 opacity-100"
                            : "scale-x-0 opacity-0"
                        )}
                      />

                      {/* Hover Indicator */}
                      <span className="absolute -bottom-0.5 left-0 w-full h-px bg-black/20 scale-x-0 group-hover:scale-x-100 transition-transform duration-300" />
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Precision Line */}
      <div className="absolute bottom-0 left-0 w-full h-px overflow-hidden">
        <div 
          className={cn(
            "h-px bg-gradient-to-r from-transparent via-black/10 to-transparent transform",
            isScrolled ? "opacity-100" : "opacity-0",
            "transition-opacity duration-300"
          )}
        />
      </div>
    </nav>
  );
};

export default Navigation;
