
import React from 'react';
import { Mail } from 'lucide-react';
import useTypewriter from '@/hooks/useTypewriter';
import { useInView } from 'react-intersection-observer';

const ContactSection = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const displayedText = useTypewriter(inView ? "Let's work together." : '', 50);
  const emailText = useTypewriter(inView ? "stuart.knott@gmail.com" : '', 30);
  
  return (
    <section ref={ref} id="contact" className="py-20 md:py-32 bg-[#1c1c1c] relative overflow-hidden">
      {/* Technical Grid Underlay */}
      <div className="absolute inset-0 grid grid-cols-2 opacity-[0.02] pointer-events-none">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="border-r border-white/20" />
        ))}
        {[...Array(4)].map((_, i) => (
          <div key={i} className="border-b border-white/20 col-span-2" />
        ))}
      </div>

      <div className="container-custom">
        <div className="reveal max-w-4xl mx-auto">
          {/* Section ID */}
          <div className="text-center mb-2">
            <span className="text-[10px] font-mono text-white/30 tracking-wider">
              [C.01]
            </span>
          </div>

          {/* Contact Header */}
          <div className="relative inline-flex flex-col items-center w-full">
            <p className="section-heading transform transition-all duration-300 hover:tracking-[0.3em] text-white">
              Contact
            </p>
            
            {/* Title with Typewriter Effect */}
            <div className="relative mb-12">
              <h2 className="title-medium !text-white font-bold tracking-wide">
                {displayedText}
                <span 
                  className={`inline-block w-0.5 h-6 bg-white ml-1 align-middle ${
                    displayedText.length === "Let's work together.".length ? "animate-pulse" : "animate-blink"
                  }`}
                />
              </h2>
              
              {/* Accent Line */}
              <div className="absolute -bottom-4 left-0 w-full h-px overflow-hidden">
                <div 
                  className="h-px bg-white/10 transform origin-left transition-transform duration-1000"
                  style={{
                    transform: `scaleX(${displayedText.length / "Let's work together.".length})`
                  }}
                />
              </div>
            </div>

            {/* Email Container */}
            <div 
              className="relative inline-flex items-center gap-3 group transition-all duration-300"
            >
              {/* Measurement Marker */}
              <div className="absolute -left-6 top-1/2 -translate-y-1/2 h-px w-4 bg-white/10" />
              
              {/* Email with Icon */}
              <Mail className="w-5 h-5 text-white/70 transition-colors duration-300 group-hover:text-white" />
              <a
                href="mailto:stuart.knott@gmail.com"
                className="relative inline-flex items-center font-mono text-lg text-white/90 hover:text-white transition-all duration-300"
              >
                {/* Email Text with Typewriter Effect */}
                <span className="relative">
                  {emailText}
                  <span 
                    className={`inline-block w-0.5 h-5 bg-white ml-1 align-middle ${
                      emailText.length === "stuart.knott@gmail.com".length ? "animate-pulse" : "animate-blink"
                    }`}
                  />
                  
                  {/* Hover Underline Effect */}
                  <span className="absolute -bottom-1 left-0 w-full h-px bg-white/20 transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100" />
                </span>
              </a>

              {/* Measurement Marker */}
              <div className="absolute -right-6 top-1/2 -translate-y-1/2 h-px w-4 bg-white/10" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
