import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Quote } from 'lucide-react';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  type CarouselApi,
} from "@/components/ui/carousel";
import { cn } from '@/lib/utils';
import DataStreamText from '../ui/data-stream-text';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import useTypewriter from '@/hooks/useTypewriter';

const testimonials = [
  {
    quote: "I had the pleasure of working with Stuart for nearly three years, and he's hands down one of the most talented editors I've encountered. What really set him apart, though, was his strategic mindset—always in tune with cultural trends and innovation, and always sharing his insights with the team.\n\nOn his account, Stuart didn't just meet expectations—he smashed them. He delivered content that was as innovative as it was impactful, consistently showcasing his editorial expertise. His forward-thinking approach—like being one of the first to dive into AI—shows he's always ready to break new ground. So, if you want game-changing ideas and standout communication strategies, Stuart's your guy.",
    author: "Matilda Egere-Cooper",
    role: "Editorial Director, Dentsu Creative"
  },
  {
    quote: "I worked with Stuart on a complex strategic project for global retail destinations and Automotive concepts. He is a diligent, flexible and creative content strategist who works well in teams and can put his mind to multiple sectors and demands. Would certainly recommend him.",
    author: "Christophe Castagnéra",
    role: "Head of Strategy – Middle East, Imagination"
  },
  {
    quote: "28 years working in the industry and the one person I'd always want on my team is Stuart. Strategic, thoughtful and gets stuff done. He's also a brilliant leader of people… when are you coming back Stuart?",
    author: "Lee Behan",
    role: "Global Commerce Director, Publicis Groupe"
  },
  {
    quote: "What's so brilliantly refreshing about Stuart is his enthusiasm for our industry's changing landscape. He understands that to succeed, brands need to be open to change, and that attitudes towards and ways of working with content must evolve too. He has transformed the learnings from his years of experience into multi-channel expertise, and is as comfortable mentoring teams as he is delivering original ideas. A genuinely smart and strategic thinker, Stuart thrives on cross-network, inter-agency collaboration.",
    author: "Sarah Bravo",
    role: "Ex-Executive Creative Director, Publicis UK"
  }
];

const TestimonialsSection = () => {
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  const [api, setApi] = useState<CarouselApi>();

  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const text = "Source Credibility : : Confirmed";
  const displayedText = useTypewriter(inView ? text : '', 30);

  React.useEffect(() => {
    if (!api) {
      return;
    }

    api.on("select", () => {
      setActiveTestimonial(api.selectedScrollSnap());
    });
  }, [api]);

  return (
    <section className="py-20 md:py-32 bg-white">
      <div className="container-custom">
        <div className="reveal" ref={ref}>
          <p className="section-heading">Testimonials</p>
          
          <div className="text-center mb-6">
            <div className="font-mono text-[10px] text-black flex items-center justify-center gap-2">
              <span className="w-2 h-px bg-black" />
              <span className={cn(
                "text-black transition-opacity duration-1500",
                displayedText.length === text.length && "animate-[pulse_3s_ease-in-out_infinite]"
              )}>{displayedText}</span>
              <span className={cn(
                "inline-block h-3 w-0.5 bg-black ml-1",
                displayedText.length === text.length ? "animate-[pulse_3s_ease-in-out_infinite]" : "animate-blink"
              )} />
              <span className="w-2 h-px bg-black" />
            </div>
          </div>

          <div className="relative mt-8">
            <Carousel 
              className="max-w-4xl mx-auto"
              opts={{
                loop: true,
              }}
              setApi={setApi}
            >
              <CarouselContent>
                {testimonials.map((testimonial, index) => (
                  <CarouselItem key={index}>
                    <div className="group">
                      {/* Technical ID */}
                      <div className="absolute top-3 right-3 z-10 flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <div className="text-[10px] font-mono text-black/60 bg-black/5 px-2 py-1 rounded-sm">
                          T_{String(index + 1).padStart(2, '0')}
                        </div>
                      </div>

                      <Card className={cn(
                        "bg-white relative overflow-hidden",
                        "before:absolute before:inset-0 before:bg-gradient-to-b before:from-black/[0.02] before:to-transparent before:opacity-0 group-hover:before:opacity-100 before:transition-opacity before:duration-500",
                      )}>
                        <CardContent className="p-6 md:p-12 relative">
                          {/* Measurement Point */}
                          <div className="absolute top-0 right-0 w-8 h-8">
                            <div className="absolute top-3 right-0 w-3 h-px bg-black/20" />
                            <div className="absolute top-0 right-3 w-px h-3 bg-black/20" />
                          </div>

                          {/* Left Data Stream Border */}
                          <div className="absolute left-0 top-0 w-px h-full">
                            <div className={cn(
                              "h-full w-full bg-gradient-to-b from-transparent via-black/10 to-transparent transform transition-transform duration-1000",
                              activeTestimonial === index ? "translate-y-0" : "translate-y-full"
                            )} />
                          </div>

                          {/* Quote Icon as Technical Indicator */}
                          <div className="relative mb-6 md:mb-8">
                            <Quote className="w-10 h-10 md:w-12 md:h-12 text-black/80" strokeWidth={1} />
                            <div className="absolute -top-1 -left-1 w-3 h-3">
                              <div className="absolute top-0 left-1.5 h-3 w-px bg-black/20" />
                              <div className="absolute top-1.5 left-0 h-px w-3 bg-black/20" />
                            </div>
                          </div>

                          {/* Testimonial Text with Typing Effect */}
                          <div className="relative mb-6 md:mb-8">
                            <DataStreamText 
                              text={testimonial.quote}
                              className="text-base md:text-lg text-left"
                            />
                          </div>

                          {/* Author Info with Technical Styling */}
                          <div className="text-right font-mono">
                            <p className="font-semibold text-black tracking-wide text-sm md:text-base">
                              {testimonial.author}
                            </p>
                            <p className="text-black/70 text-xs md:text-sm tracking-wider">
                              {testimonial.role}
                            </p>
                          </div>

                          {/* Progress Line */}
                          <div className="absolute bottom-0 left-0 w-full h-px overflow-hidden">
                            <div className={cn(
                              "h-px w-full transform transition-transform duration-1000",
                              "bg-gradient-to-r from-transparent via-black/20 to-transparent",
                              activeTestimonial === index ? "translate-x-0" : "-translate-x-full"
                            )} />
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>

              {/* Technical Navigation Controls */}
              <CarouselPrevious className="absolute -left-2 sm:-left-3 md:-left-8 lg:-left-12 h-7 w-7 sm:h-8 sm:w-8 md:h-10 md:w-10 bg-white/80 backdrop-blur-sm hover:bg-white border-black/10 hover:border-black/20" />
              <CarouselNext className="absolute -right-2 sm:-right-3 md:-right-8 lg:-right-12 h-7 w-7 sm:h-8 sm:w-8 md:h-10 md:w-10 bg-white/80 backdrop-blur-sm hover:bg-white border-black/10 hover:border-black/20" />
            </Carousel>

            {/* Progress Indicators */}
            <div className="mt-6 flex justify-center gap-1">
              {testimonials.map((_, index) => (
                <div
                  key={index}
                  className={cn(
                    "h-px transition-all duration-300",
                    index === activeTestimonial ? "w-8 bg-black/40" : "w-4 bg-black/20"
                  )}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
