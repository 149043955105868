
import React, { useState, useEffect } from 'react';
import { Brain, Users, Palette, LineChart, Share2, BarChart3, Cpu } from 'lucide-react';
import PrecisionOptics from '../visualizations/PrecisionOptics';
import PrecisionCarousel from '../ui/precision-carousel';
import { cn } from '@/lib/utils';
import useTypewriter from '@/hooks/useTypewriter';
import { useInView } from 'react-intersection-observer';

const precisionItems = [
  {
    title: "Marketing",
    description: "Developing comprehensive content strategies aligned with business objectives",
    icon: Brain
  },
  {
    title: "Technology",
    description: "Crafting compelling narratives across multiple platforms and formats",
    icon: Palette
  },
  {
    title: "Finance",
    description: "Building and maintaining consistent brand voices and identities",
    icon: Users
  },
  {
    title: "Automotive",
    description: "Creating and distributing valuable content to attract and retain audiences",
    icon: Share2
  },
  {
    title: "Entertainment",
    description: "Developing and executing social media strategies across multiple platforms",
    icon: LineChart
  }
];

const expertiseItems = [
  {
    title: "Content Strategy",
    description: "Developing comprehensive content strategies aligned with business objectives",
    icon: Brain
  },
  {
    title: "Digital Storytelling",
    description: "Crafting compelling narratives across multiple platforms and formats",
    icon: Palette
  },
  {
    title: "Brand Development",
    description: "Building and maintaining consistent brand voices and identities",
    icon: Users
  },
  {
    title: "Content Marketing",
    description: "Creating and distributing valuable content to attract and retain target audiences",
    icon: Share2
  },
  {
    title: "Social Media Management",
    description: "Developing and executing social media strategies across multiple platforms",
    icon: LineChart
  },
  {
    title: "Analytics & Reporting",
    description: "Measuring and analyzing content performance to drive data-driven decisions",
    icon: BarChart3
  },
  {
    title: "Generative AI Integration",
    description: "Leveraging cutting-edge AI technologies to enhance content creation and optimization",
    icon: Cpu
  }
];

const ExpertiseSection = () => {
  const { ref, inView } = useInView({
    threshold: 0.1, // Lowered threshold for earlier triggering
    triggerOnce: true
  });

  const [showInitText, setShowInitText] = useState(true);
  const [showContent, setShowContent] = useState(false);
  
  const initText = "Domain Expertise: Scanning";
  const displayedText = useTypewriter(inView ? initText : '', 30); // Increased typing speed

  useEffect(() => {
    if (inView) {
      // Start showing content sooner
      const timer = setTimeout(() => {
        setShowInitText(false);
        setShowContent(true);
      }, 1000); // Reduced delay
      return () => clearTimeout(timer);
    }
  }, [inView]);

  return (
    <section id="expertise" className="py-12 md:py-16 bg-[#F6F6F7]">
      <div className="container-custom">
        <div className="max-w-5xl mx-auto" ref={ref}>
          <p className="section-heading">
            Expertise
          </p>

          <div className="text-center mb-8">
            <div className={cn(
              "font-mono text-[10px] text-black flex items-center justify-center gap-2",
              displayedText === initText ? "animate-pulse" : ""
            )}>
              <span className="w-2 h-px bg-black" />
              <span className="text-black">{displayedText}</span>
              <span className={cn(
                "inline-block h-3 w-0.5 bg-black ml-1",
                displayedText === initText ? "animate-pulse" : "animate-blink"
              )} />
              <span className="w-2 h-px bg-black" />
            </div>
          </div>
          
          <div className={cn(
            "transition-opacity duration-500",
            showContent ? "opacity-100" : "opacity-0"
          )}>
            <PrecisionOptics 
              items={precisionItems}
              className="mb-8"
            />
            <PrecisionCarousel items={expertiseItems} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExpertiseSection;
