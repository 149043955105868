
import React from 'react';

interface ProjectMeasureProps {
  index: number;
  projectType: string;
}

const ProjectMeasure = ({ index, projectType }: ProjectMeasureProps) => {
  return (
    <div className="absolute inset-0 pointer-events-none">
      {/* Precision corner marker */}
      <div className="absolute top-0 right-0 w-8 h-8">
        <div className="absolute top-3 right-0 w-3 h-px bg-white/20" />
        <div className="absolute top-0 right-3 w-px h-3 bg-white/20" />
        <div className="text-[8px] absolute top-1 right-4 font-mono text-white/40">
          {`P_${(index + 1).toString().padStart(2, '0')}`}
        </div>
      </div>
      
      {/* Project type label */}
      <div className="absolute bottom-4 left-4 font-mono text-[10px] tracking-wider text-white/60">
        {projectType}
      </div>

      {/* Scan line effect */}
      <div className="absolute inset-0 overflow-hidden opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <div className="absolute inset-x-0 h-px bg-white/10 animate-scan" />
      </div>
    </div>
  );
};

export default ProjectMeasure;
