
import React from 'react';
import ProjectCard from './ProjectCard';
import { Project } from '@/types/project';

interface ProjectsGridProps {
  projects: Project[];
  visibleProjects: number;
  expandedProject: number | null;
  onProjectClick: (index: number) => void;
}

const ProjectsGrid = ({ projects, visibleProjects, expandedProject, onProjectClick }: ProjectsGridProps) => {
  return (
    <div className="relative">
      {/* Grid markers */}
      <div className="absolute inset-0 grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 pointer-events-none">
        {Array.from({ length: Math.ceil(visibleProjects / 2) }).map((_, i) => (
          <React.Fragment key={i}>
            <div className="hidden sm:block h-full border-r border-black/[0.02]" />
            <div className="hidden sm:block h-full border-r border-black/[0.02]" />
          </React.Fragment>
        ))}
      </div>

      {/* Project cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 relative">
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            {...project}
            index={index}
            isExpanded={expandedProject === index}
            onClick={() => onProjectClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectsGrid;
