
import React from 'react';
import { Project } from '@/types/project';
import ProjectMeasure from './ProjectMeasure';

interface ProjectCardProps extends Project {
  index: number;
  isExpanded: boolean;
  onClick: () => void;
}

const ProjectCard = ({ image, title, description, index, isExpanded, onClick }: ProjectCardProps) => {
  const projectType = title.split(' ')[0].toUpperCase();

  return (
    <div className="flex flex-col">
      <div
        onClick={onClick}
        className={`group relative overflow-hidden rounded-sm aspect-video bg-[#1c1c1c] cursor-pointer transform transition-all duration-500 ${
          isExpanded ? 'scale-100' : 'hover:scale-[1.02]'
        }`}
        style={{ animationDelay: `${index * 200}ms` }}
      >
        <ProjectMeasure index={index} projectType={projectType} />
        <div
          className={`absolute inset-0 transition-transform duration-500 ${
            isExpanded ? 'translate-y-full' : 'translate-y-0'
          }`}
        >
          <div className="relative w-full h-full">
            <img 
              src={image}
              alt={title}
              className="object-cover w-full h-full transition-all duration-500 grayscale group-hover:grayscale-0"
            />
            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <div className="absolute inset-0 border border-white/20" />
            </div>
          </div>
        </div>

        <div 
          className={`absolute inset-0 bg-black/90 p-4 sm:p-6 flex flex-col justify-center items-center transform transition-transform duration-500 ${
            isExpanded ? 'translate-y-0' : 'translate-y-full'
          }`}
        >
          <h3 className="text-lg sm:text-xl font-semibold text-white mb-2 sm:mb-4 text-center font-mono tracking-wide">
            {title}
          </h3>
          <p className="text-white/90 text-sm sm:text-base whitespace-pre-line text-center max-h-[calc(100%-4rem)] overflow-y-auto px-2 leading-relaxed">
            {description}
          </p>
        </div>
      </div>

      {!isExpanded && (
        <h3 className="text-base sm:text-lg font-medium text-center mt-4 text-black font-mono tracking-wide">
          {title}
        </h3>
      )}
    </div>
  );
};

export default ProjectCard;
