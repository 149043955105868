
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import './index.css';

// Clear any existing service worker cache
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });
}

// Add cache busting query parameter to prevent stale assets
const cacheBuster = Date.now();
const linkElements = document.getElementsByTagName('link');
for (let i = 0; i < linkElements.length; i++) {
  const currentHref = linkElements[i].href;
  if (currentHref) {
    linkElements[i].href = `${currentHref}${currentHref.includes('?') ? '&' : '?'}v=${cacheBuster}`;
  }
}

const scriptElements = document.getElementsByTagName('script');
for (let i = 0; i < scriptElements.length; i++) {
  const currentSrc = scriptElements[i].src;
  if (currentSrc) {
    scriptElements[i].src = `${currentSrc}${currentSrc.includes('?') ? '&' : '?'}v=${cacheBuster}`;
  }
}

// Force the browser to always check for fresh content
const meta = document.createElement('meta');
meta.httpEquiv = 'Cache-Control';
meta.content = 'no-cache, no-store, must-revalidate';
document.head.appendChild(meta);

// Force a clean mount of the app
const rootElement = document.getElementById("root");
if (rootElement) {
  while (rootElement.firstChild) {
    rootElement.removeChild(rootElement.firstChild);
  }
  createRoot(rootElement).render(<App />);
}
