
import React, { useEffect, useState } from 'react';
import { FlaskConical } from 'lucide-react';
import useTypewriter from '../../hooks/useTypewriter';
import { useInView } from 'react-intersection-observer';
import TechReticle from '../ui/tech-reticle';

const SuperpowerSection = () => {
  const [currentLine, setCurrentLine] = useState(0);
  const [displayedLines, setDisplayedLines] = useState<string[]>([]);
  const lines = [
    "Give me your densest data",
    "and most intricate insights.",
    "Share your thorniest specs",
    "and technical challenges.",
    "",
    "I'll transform them into stories that shift minds, change cultures and shape futures."
  ];

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  const displayedText = useTypewriter(inView ? lines[currentLine] : '', 30);

  useEffect(() => {
    if (displayedText === lines[currentLine]) {
      const timer = setTimeout(() => {
        setDisplayedLines(prev => [...prev, lines[currentLine]]);
        if (currentLine < lines.length - 1) {
          setCurrentLine(prev => prev + 1);
        }
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [displayedText, currentLine]);

  return (
    <section ref={ref} className="py-20 md:py-32 bg-[#1c1c1c] relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-[#1c1c1c] to-[#2a2a2a] opacity-90"></div>
      <div className="container-custom relative">
        <div className="reveal max-w-4xl mx-auto text-center">
          <p className="section-heading text-white">
            Superpower
          </p>
          <TechReticle className="mt-8" text="Skill Set [01-XX] : : Confirmed">
            <div className="flex flex-col items-center justify-center space-y-6">
              <div className="flex flex-col items-center gap-4">
                <div className="relative group">
                  <div className="absolute inset-0 bg-white/5 rounded-full blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                  <FlaskConical 
                    className={`w-12 h-12 text-white/90 relative z-10 transition-transform duration-500 group-hover:scale-105 ${inView ? 'animate-spin-once' : ''}`}
                    strokeWidth={1}
                  />
                </div>
                <h2 className="text-3xl md:text-4xl font-bold tracking-wide transition-all duration-300 hover:tracking-wider text-white font-space-grotesk">
                  B2B Alchemy
                </h2>
              </div>
              <div className="text-lg md:text-xl text-white leading-relaxed max-w-2xl mx-auto min-h-[200px] font-space-grotesk">
                {lines.map((line, index) => (
                  <div
                    key={index}
                    className={`transition-opacity duration-500 ${
                      index <= currentLine ? 'opacity-100' : 'opacity-0'
                    } ${line === "" ? "mt-6" : ""}`}
                  >
                    {index === currentLine ? displayedText : displayedLines[index] || ''}
                  </div>
                ))}
              </div>
            </div>
          </TechReticle>
        </div>
      </div>
    </section>
  );
};

export default SuperpowerSection;
