
import React from 'react';
import TechReticle from '../ui/tech-reticle';
import { useInView } from 'react-intersection-observer';
import useTypewriter from '@/hooks/useTypewriter';
import { cn } from '@/lib/utils';

const AboutSection = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
    initialInView: false
  });

  const title = "I'm a content director who believes in the power of story to drive business transformation.";
  const displayedText = useTypewriter(inView ? title : '', 30);

  return (
    <section id="about" className="py-20 md:py-32 relative overflow-hidden bg-black">
      <div className="absolute inset-0 bg-gradient-to-b from-black via-black to-black opacity-80"></div>
      
      <div className="container-custom relative">
        <div className="reveal max-w-4xl mx-auto" ref={ref}>
          <TechReticle text="User_Profile_Authenticated">
            <div className="space-y-12 animate-fadeIn text-center">
              <div className="space-y-4">
                <p className="section-heading text-white transform transition-all duration-300 hover:tracking-[0.3em]">
                  About
                </p>
                <h2 className="text-3xl md:text-4xl font-bold mb-8 leading-snug text-white min-h-[120px]">
                  {displayedText}
                  <span className={cn(
                    "inline-block h-6 w-0.5 bg-white ml-1",
                    displayedText.length === title.length ? "animate-pulse" : "animate-blink"
                  )}></span>
                </h2>
              </div>
              
              <p className="text-white/90 text-lg md:text-xl leading-relaxed tracking-wide font-space-grotesk max-w-2xl mx-auto">
                My approach combines deep editorial expertise with commercial acumen, ensuring every piece of content works hard for your business while engaging your audience.
              </p>
            </div>
          </TechReticle>
        </div>
      </div>

      <style>{`
        @keyframes blink {
          0%, 100% { opacity: 1; }
          50% { opacity: 0; }
        }
        .animate-blink {
          animation: blink 0.8s infinite;
        }
      `}</style>
    </section>
  );
};

export default AboutSection;
