
import React, { useEffect, useRef, useState } from 'react';
import { cn } from '@/lib/utils';
import useTypewriter from '@/hooks/useTypewriter';

interface DataStreamTextProps {
  text: string;
  className?: string;
  onComplete?: () => void;
}

const DataStreamText = ({ text, className, onComplete }: DataStreamTextProps) => {
  const [displayedGroups, setDisplayedGroups] = useState<string[]>([]);
  const [isComplete, setIsComplete] = useState(false);
  const [showInitText, setShowInitText] = useState(true);
  const [startMainText, setStartMainText] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const initText = "System Initialised: Analysing Capabilities";
  const displayedInitText = useTypewriter(showInitText ? initText : '', 50);
  
  // Debug logs to track state
  console.log('Init Text:', {
    displayedInitText,
    showInitText,
    initText,
    startMainText
  });

  useEffect(() => {
    // Only proceed if we have the full init text
    if (displayedInitText === initText) {
      console.log('Init text complete, starting timer');
      const timer = setTimeout(() => {
        setShowInitText(false);
        setTimeout(() => setStartMainText(true), 500);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [displayedInitText, initText]);

  useEffect(() => {
    if (!startMainText) return;

    const splitTextIntoGroups = (text: string): string[] => {
      return text.split(/(\s+)/).filter(Boolean);
    };

    const groups = splitTextIntoGroups(text);
    let currentIndex = 0;
    
    const animateGroups = () => {
      if (currentIndex <= groups.length) {
        setDisplayedGroups(groups.slice(0, currentIndex));
        currentIndex++;

        const baseDelay = 50;
        const randomDelay = Math.random() * 25;
        
        if (currentIndex <= groups.length) {
          setTimeout(animateGroups, baseDelay + randomDelay);
        } else {
          setIsComplete(true);
          onComplete?.();
        }
      }
    };

    animateGroups();
  }, [text, onComplete, startMainText]);

  return (
    <div className={cn(
      "relative font-mono text-center py-4 px-6 bg-black/[0.02] rounded-lg",
      "max-w-[800px] mx-auto",
      className
    )}>
      <div ref={containerRef} className="relative min-h-[1.5em]">
        <div className="relative leading-[1.4] tracking-wide">
          {showInitText && displayedInitText && (
            <span className="whitespace-pre-wrap text-[1.1rem] md:text-[1.25rem] lg:text-[1.4rem] text-black/90">
              {displayedInitText}
            </span>
          )}
          {!showInitText && startMainText && (
            <span className="whitespace-pre-wrap text-[1.1rem] md:text-[1.25rem] lg:text-[1.4rem]">
              {displayedGroups.map((group, groupIndex) => (
                <span
                  key={groupIndex}
                  className={cn(
                    "animate-fadeIn opacity-0",
                    "text-black/90"
                  )}
                  style={{
                    animationDelay: `${groupIndex * 50}ms`,
                    animationFillMode: 'forwards',
                    textShadow: '0 0 1px rgba(0,0,0,0.1)'
                  }}
                >
                  {group}
                </span>
              ))}
            </span>
          )}
          
          <span 
            className={cn(
              "inline-block w-2 h-5 align-middle bg-black animate-pulse",
              showInitText && "bg-black"
            )}
            style={{ animationDuration: '1s' }}
          />
        </div>

        <div className="absolute left-0 top-0 h-full w-1 bg-gradient-to-b from-transparent via-blue-500/5 to-transparent" />
      </div>
    </div>
  );
};

export default DataStreamText;
