import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { cn } from '@/lib/utils';
import TechReticle from '../ui/tech-reticle';

interface PrecisionOpticsProps {
  items: {
    title: string;
    metrics?: number[];
  }[];
  className?: string;
}

const PrecisionOptics: React.FC<PrecisionOpticsProps> = ({ items, className }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const [scanPositions, setScanPositions] = useState<{ left: number, opacity: number }[]>(
    Array(items.length).fill({ left: -100, opacity: 0 })
  );

  useEffect(() => {
    if (inView) {
      const interval = setInterval(() => {
        setScanPositions(prev => prev.map(() => ({
          left: Math.random() > 0.5 ? -100 : 100,
          opacity: Math.random() > 0.5 ? 0.8 : 0
        })));
      }, 1500);

      return () => clearInterval(interval);
    }
  }, [inView, items.length]);

  return (
    <TechReticle>
      <div 
        ref={ref} 
        className={cn(
          "relative min-h-[200px] flex flex-col items-center justify-center gap-2 py-2",
          className
        )}
      >
        {items.map((item, index) => (
          <div 
            key={item.title}
            style={{ animationDelay: `${index * 200}ms` }}
            className={cn(
              "relative flex items-center justify-center w-full mb-1",
              !inView && "opacity-0"
            )}
          >
            {/* Left measurement line */}
            <div className="flex-1 h-[1px] bg-black relative">
              {/* Measurement points */}
              {[0.33, 0.66].map((point, i) => (
                <div 
                  key={i}
                  className="absolute top-1/2 -translate-y-1/2"
                  style={{ right: `${point * 100}%` }}
                >
                  <div className="h-1 w-[1px] bg-black" />
                  <div className="text-[6px] text-black mt-0.5 font-mono font-bold">
                    {(point * 100).toFixed(0)}
                  </div>
                </div>
              ))}
            </div>

            {/* Center word with technical frame */}
            <div className="relative px-4 min-w-[160px] flex justify-center">
              {/* Left vertical line */}
              <div className="absolute left-0 top-0 h-[300%] w-[1px] bg-black z-10" style={{ transform: 'translateY(-33.33%)' }} />
              
              {/* Right vertical line */}
              <div className="absolute right-0 top-0 h-[300%] w-[1px] bg-black z-10" style={{ transform: 'translateY(-33.33%)' }} />
              
              <div className="absolute inset-0 border border-black">
                {/* Corner markers */}
                <div className="absolute -top-0.5 -left-0.5 w-1 h-1 border-t border-l border-black" />
                <div className="absolute -top-0.5 -right-0.5 w-1 h-1 border-t border-r border-black" />
                <div className="absolute -bottom-0.5 -left-0.5 w-1 h-1 border-b border-l border-black" />
                <div className="absolute -bottom-0.5 -right-0.5 w-1 h-1 border-b border-r border-black" />
              </div>

              {/* Title text */}
              <div className="relative z-10 py-1 text-black font-mono tracking-wider text-sm font-bold">
                {item.title}
              </div>

              {/* Horizontal scanning effect */}
              <div
                className="absolute inset-0 bg-gradient-to-r from-transparent via-black/20 to-transparent transition-all duration-500 ease-in-out"
                style={{
                  transform: `translateX(${scanPositions[index]?.left}%)`,
                  opacity: scanPositions[index]?.opacity || 0,
                  width: '200%',
                  left: '-50%'
                }}
              />
            </div>

            {/* Right measurement line */}
            <div className="flex-1 h-[1px] bg-black relative">
              {/* Measurement points */}
              {[0.33, 0.66].map((point, i) => (
                <div 
                  key={i}
                  className="absolute top-1/2 -translate-y-1/2"
                  style={{ left: `${point * 100}%` }}
                >
                  <div className="h-1 w-[1px] bg-black" />
                  <div className="text-[6px] text-black mt-0.5 font-mono font-bold">
                    {(point * 100).toFixed(0)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </TechReticle>
  );
};

export default PrecisionOptics;
