
import React, { useState } from 'react';
import ProjectsGrid from '../projects/ProjectsGrid';
import { Project } from '@/types/project';
import { useInView } from 'react-intersection-observer';
import useTypewriter from '@/hooks/useTypewriter';
import { cn } from '@/lib/utils';

const projects: Project[] = [
  {
    image: "/lovable-uploads/776dd903-ef64-4c58-88b8-d365a4966982.png",
    title: "Data Visualisation",
    description: "I developed the Lloyd's City Risk Index portal in partnership with the Judge Business School at Cambridge University. This project combined Google Maps with risk analytics to transform complex data into an accessible, interactive experience. The launch gained significant international coverage, including features in the Wall Street Journal and BBC Radio 4, attracting tens of thousands of views within days."
  },
  {
    image: "/lovable-uploads/2da91826-75fe-4c27-8fa8-29137a01a94f.png",
    title: "Campaign Integration",
    description: "I led St. James's Place's Responsible Investment campaign, taking over their digital estate with a comprehensive content programme that included a campaign landing page, local press ads, thought leadership articles, video content, direct mail, social assets – and a high-profile webinar featuring leading UK climate experts. I also authored a speculative fiction piece set 100 years in the future, 'when the battle for a better future has been won, but the cynics have now cast their eyes to the stars'."
  },
  {
    image: "/lovable-uploads/70d08ad1-c32a-439a-ba86-ed878ec8678a.png",
    title: "Influencer Content",
    description: "I conceptualised and executed BT's Cyber Agility campaign from strategy to delivery. I created a social-first influencer programme featuring interviews with People Hacker Jenny Radcliffe and thought leadership video posts from other amplifiers. The campaign included a live LinkedIn roundtable with industry experts, transforming complex security topics into engaging digital narratives that effectively promoted BT's new positioning."
  },
  {
    image: "/lovable-uploads/65690fac-e271-43f5-ae4c-4ce664578835.png",
    title: "Digital Hubs",
    description: "I developed Honda's Engine Room, an optimised content hub designed to broaden the brand's appeal beyond core enthusiasts. I created 16 new multi-format assets monthly, aligned with key calendar moments and product launches. Content was activated across eCRM, owned and paid social channels, featuring diverse video content including car walkthroughs, influencer challenges and owner interviews."
  },
  {
    image: "/lovable-uploads/8a5dc4a6-f55b-4293-8041-001b9f3a83dd.png",
    title: "Brand Building",
    description: "I was an editorial lead across Virgin Media's integrated content strategy, delivering an engaging multi-platform editorial programme that allowed customers to discover more of their entertainment services. The centrepiece was the UK's first augmented reality customer magazine using Blippar technology, bringing cover stars like Usain Bolt to life. Supporting digital content across email, web, apps and social media created a connected journey, driving deeper engagement with Virgin Media's services and reducing customer churn through personalised content discovery."
  },
  {
    image: "/lovable-uploads/d84ccd8d-5fff-4853-9a0a-60d2d741cdec.png",
    title: "Print Innovation",
    description: "I produced the Print Power Card Game, reimagining B2B engagement through a premium physical card deck. This innovative approach adapted a party game format, combining industry satire with strategic messaging. The dual-purpose gameplay elements created memorable connections whilst delivering key data points through a companion rulebook, offering an engaging way to communicate complex B2B information."
  },
  {
    image: "/lovable-uploads/0469b3aa-e721-4fd4-80e4-5aabbc4058bd.png",
    title: "Social Content",
    description: "I managed Citi Private Pass's high-profile influencer programme across entertainment, sports and lifestyle sectors. I secured exclusive coverage through music journalists for events like Guns N' Roses at the Rose Bowl and Global Citizen Festival, while arranging food influencer experiences with creators like Hangry Diary. The programme included athlete meet-and-greets with sporting superstars like Justin Thomas and Odell Beckham Jr."
  },
  {
    image: "/lovable-uploads/deec5808-d0fd-448e-8ec4-0a926094acb2.png",
    title: "Content Strategy",
    description: "I led the strategic planning for Jaguar Land Rover's Tour Experience, an automotive experiential marketing campaign. I created two powerful themes: \"Test Your Limits\" for Jaguar and \"Would You Dare?\" for Land Rover. My approach crafted a culturally resonant journey that balanced Middle Eastern traditions with modern aspirations across digital and physical touchpoints."
  },
  {
    image: "/lovable-uploads/b107d411-d235-4a1c-a43b-b0db8170b5cb.png",
    title: "Tone of Voice",
    description: "I created Print Power's brand voice guidelines, articulating how print's enduring power can be conveyed through language. The guidelines established a clear, authoritative yet warm tone that balanced technical knowledge with accessibility. Focusing on print's tangible benefits, the copy standards helped unified communications across European markets while maintaining consistent messaging about sustainability, effectiveness and innovation in the print medium. I developed practical examples showing how to bring print's sensory and emotional qualities to life through words."
  },
  {
    image: "/lovable-uploads/ad6dba6c-e4e3-4369-86f1-9796f4d5ca15.png",
    title: "B2B Thought Leadership",
    description: "I created an innovative special edition of Royal Mail's Contact Magazine, transforming abstract marketing concepts into tangible experiences through pioneering print techniques. The Sensory Issue featured foil-blocking, scented inserts, and interactive elements, with contributions from world-renowned sensory practitioners. The issue earned industry praise and won the BSME Editor of the Year Award for Innovation & Brand-building."
  }
];

const ProjectsSection = () => {
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [expandedProject, setExpandedProject] = useState<number | null>(null);

  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const text = "Project Database Accessed : : Details Loading";
  const displayedText = useTypewriter(inView ? text : '', 30);

  const handleProjectClick = (index: number) => {
    setExpandedProject(expandedProject === index ? null : index);
  };

  const visibleProjects = showAllProjects ? projects.length : 4;

  return (
    <section id="projects" className="py-20 md:py-32 bg-white transition-colors duration-500">
      <div className="container-custom">
        <div className="reveal" ref={ref}>
          <p className="section-heading text-black transform transition-all duration-300 hover:tracking-[0.3em] font-mono mb-8 text-center">
            Selected Projects
          </p>
          
          <div className="text-center mb-12">
            <div className={cn(
              "font-mono text-[10px] text-black flex items-center justify-center gap-2",
              inView && displayedText === text ? "animate-pulse" : ""
            )}>
              <span className="w-2 h-px bg-black" />
              <span className={cn(
                "text-black transition-opacity duration-1000",
                inView ? "animate-pulse" : ""
              )}>{displayedText}</span>
              <span className={cn(
                "inline-block h-3 w-0.5 bg-black ml-1",
                displayedText.length === text.length ? "animate-pulse" : "animate-blink"
              )} />
              <span className="w-2 h-px bg-black" />
            </div>
          </div>

          <ProjectsGrid
            projects={projects.slice(0, visibleProjects)}
            visibleProjects={visibleProjects}
            expandedProject={expandedProject}
            onProjectClick={handleProjectClick}
          />
          <div className="mt-12 text-center">
            <button
              onClick={() => setShowAllProjects(!showAllProjects)}
              className="px-6 py-3 bg-black text-white rounded-sm hover:bg-black/90 transition-colors font-mono text-sm tracking-wider"
            >
              {showAllProjects ? 'Show Less' : 'View More Projects'}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;
