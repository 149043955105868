
import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { cn } from '@/lib/utils';
import useTypewriter from '@/hooks/useTypewriter';

interface TechReticleProps {
  children: React.ReactNode;
  className?: string;
  text?: string;  // Added text prop
}

const TechReticle = ({ children, className, text = "TARGET_ACQUIRED::DATA_STREAM" }: TechReticleProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.2,
    triggerOnce: false
  });

  const displayedText = useTypewriter(inView ? text : '', 50);

  // Combine refs
  const setRefs = (element: HTMLDivElement | null) => {
    containerRef.current = element;
    inViewRef(element);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;
      
      const rect = containerRef.current.getBoundingClientRect();
      const scrollProgress = Math.max(0, Math.min(1, 1 - (rect.top / window.innerHeight)));
      
      // Update frame expansion based on scroll - maintaining right angles
      const corners = containerRef.current.querySelectorAll('.corner');
      corners.forEach((corner) => {
        const baseSize = 32;
        const maxExpansion = 64;
        const size = baseSize + (scrollProgress * maxExpansion);
        
        const cornerElement = corner as HTMLElement;
        cornerElement.style.width = `${size}px`;
        cornerElement.style.height = `${size}px`;
        // Remove any transform that might affect the angle
        cornerElement.style.transform = 'none';
      });

      // Update data points opacity based on scroll
      const dataPoints = containerRef.current.querySelectorAll('.data-point');
      dataPoints.forEach((point) => {
        (point as HTMLElement).style.opacity = String(Math.min(1, scrollProgress * 2));
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div ref={setRefs} className={cn("relative p-16", className)}>
      {/* Corner Elements with Dynamic Animation */}
      <div className={cn(
        "absolute inset-0 pointer-events-none transition-opacity duration-500",
        inView ? "opacity-100" : "opacity-0"
      )}>
        {/* Top Left Corner */}
        <div className="corner absolute top-0 left-0 w-8 h-8 border-l-2 border-t-2 border-white/30 origin-top-left transition-all duration-300">
          <div className="data-point absolute -top-4 -left-4 text-[8px] font-mono text-white/30">x:0</div>
        </div>
        
        {/* Top Right Corner */}
        <div className="corner absolute top-0 right-0 w-8 h-8 border-r-2 border-t-2 border-white/30 origin-top-right transition-all duration-300">
          <div className="data-point absolute -top-4 -right-4 text-[8px] font-mono text-white/30">y:0</div>
        </div>
        
        {/* Bottom Left Corner */}
        <div className="corner absolute bottom-0 left-0 w-8 h-8 border-l-2 border-b-2 border-white/30 origin-bottom-left transition-all duration-300">
          <div className="data-point absolute -bottom-4 -left-4 text-[8px] font-mono text-white/30">y:100</div>
        </div>
        
        {/* Bottom Right Corner */}
        <div className="corner absolute bottom-0 right-0 w-8 h-8 border-r-2 border-b-2 border-white/30 origin-bottom-right transition-all duration-300">
          <div className="data-point absolute -bottom-4 -right-4 text-[8px] font-mono text-white/30">x:100</div>
        </div>
      </div>

      {/* Dynamic Border that pulses with scroll */}
      <div className={cn(
        "absolute inset-0 pointer-events-none",
        inView ? "opacity-100" : "opacity-0"
      )}>
        {/* Horizontal borders with gradient and pulse effect */}
        <div className="absolute inset-x-8 top-0 h-px bg-gradient-to-r from-transparent via-white/30 to-transparent transform -translate-y-0.5 animate-pulse" />
        <div className="absolute inset-x-8 bottom-0 h-px bg-gradient-to-r from-transparent via-white/30 to-transparent transform translate-y-0.5 animate-pulse" />
        
        {/* Vertical borders with gradient and pulse effect */}
        <div className="absolute inset-y-8 left-0 w-px bg-gradient-to-b from-transparent via-white/30 to-transparent transform -translate-x-0.5 animate-pulse" />
        <div className="absolute inset-y-8 right-0 w-px bg-gradient-to-b from-transparent via-white/30 to-transparent transform translate-x-0.5 animate-pulse" />
      </div>

      {/* Digital noise overlay */}
      <div className="absolute inset-0 mix-blend-overlay opacity-20">
        <div className="absolute inset-0 bg-[radial-gradient(circle,_transparent_1px,_rgba(255,255,255,0.03)_1px)] bg-[length:4px_4px]" />
      </div>

      {/* HUD Elements with typewriter effect and calibration markers */}
      <div className={cn(
        "absolute left-1/2 -bottom-8 -translate-x-1/2 text-[10px] text-white/30 font-mono transition-all duration-500",
        inView ? "opacity-100" : "opacity-0"
      )}>
        <div className="flex items-center gap-2">
          <span className="w-2 h-px bg-white/30" />
          {displayedText}
          <span className={cn(
            "inline-block h-3 w-0.5 bg-white/30 ml-1",
            displayedText.length === text.length ? "animate-pulse" : "animate-blink"
          )} />
          <span className="w-2 h-px bg-white/30" />
        </div>
      </div>

      {/* Scan Lines Effect */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        <div className="absolute inset-0 bg-scan-lines opacity-10" />
        <div className="absolute inset-0 animate-scan bg-gradient-to-b from-transparent via-white/5 to-transparent" />
      </div>

      {/* Content */}
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};

export default TechReticle;
