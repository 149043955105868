
import React, { useEffect, useRef, useState } from 'react';

const ProfileImage = ({ imageSrc, alt }: { imageSrc: string; alt: string }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [hoveredCircle, setHoveredCircle] = useState<number | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;
      const rect = containerRef.current.getBoundingClientRect();
      const scrollProgress = 1 - (rect.top / window.innerHeight);
      const rings = containerRef.current.querySelectorAll('.scan-ring');
      
      rings.forEach((ring, index) => {
        const direction = index === 0 ? 1 : -1;
        const rotation = scrollProgress * 360 * direction;
        (ring as HTMLElement).style.transform = `rotate(${rotation}deg)`;
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div ref={containerRef} className="relative w-32 h-32 md:w-40 md:h-40">
      {/* Broken circular paths */}
      <div className="absolute inset-0">
        {[...Array(2)].map((_, i) => (
          <div
            key={`ring-${i}`}
            className="scan-ring absolute inset-0 rounded-full transition-transform duration-300"
            style={{
              transform: 'rotate(0deg)',
              scale: i === 0 ? '1.1' : '1.3',
            }}
          >
            {/* Create the actual ring with breaks */}
            <div className="absolute inset-0">
              {i === 0 ? (
                // Inner ring - three segments
                [...Array(3)].map((_, index) => (
                  <div
                    key={`segment-${index}`}
                    className="absolute inset-0"
                    style={{
                      borderRadius: '50%',
                      border: '0.5px solid #333333',
                      clipPath: `polygon(50% 50%, 0 0, ${100 * Math.cos((index + 1) * 2 * Math.PI / 3 - Math.PI/6)}% ${100 * Math.sin((index + 1) * 2 * Math.PI / 3 - Math.PI/6)}%, ${100 * Math.cos(index * 2 * Math.PI / 3 - Math.PI/6)}% ${100 * Math.sin(index * 2 * Math.PI / 3 - Math.PI/6)}%)`,
                    }}
                  />
                ))
              ) : (
                // Outer ring - four segments
                [...Array(4)].map((_, index) => (
                  <div
                    key={`segment-${index}`}
                    onMouseEnter={() => setHoveredCircle(index + 1)}
                    onMouseLeave={() => setHoveredCircle(null)}
                    className="absolute inset-0 cursor-pointer"
                    style={{
                      borderRadius: '50%',
                      border: '0.5px solid #333333',
                      clipPath: `polygon(50% 50%, ${100 * Math.cos(index * Math.PI / 2)}% ${100 * Math.sin(index * Math.PI / 2)}%, ${100 * Math.cos((index + 0.8) * Math.PI / 2)}% ${100 * Math.sin((index + 0.8) * Math.PI / 2)}%)`,
                    }}
                  />
                ))
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Measurement markers */}
      <div className="absolute inset-0">
        {[0, 90, 180, 270].map((angle) => (
          <div
            key={`marker-${angle}`}
            className="absolute"
            style={{
              top: '50%',
              left: '50%',
              width: '24px',
              height: '0.5px',
              backgroundColor: '#333333',
              transform: `rotate(${angle}deg) translateX(${88}px)`,
              transformOrigin: '0 0'
            }}
          >
            <div className="absolute right-0 w-0.5 h-3 -top-1.5 bg-[#333333]" />
          </div>
        ))}
      </div>

      {/* Image container */}
      <div className="relative w-full h-full rounded-full overflow-hidden">
        <img
          src={imageSrc}
          alt={alt}
          className={`w-full h-full object-cover ${hoveredCircle === null ? 'grayscale' : ''}`}
        />
      </div>
    </div>
  );
};

export default ProfileImage;
