
import HeroContent from './hero/HeroContent';
import ProfileImage from './hero/ProfileImage';
import useRevealOnScroll from '../hooks/useRevealOnScroll';

const Hero = () => {
  const heroRef = useRevealOnScroll();

  return (
    <section className="min-h-screen flex flex-col items-center justify-center relative bg-white">
      <div className="mt-32 md:mt-36 mb-6">
        <ProfileImage
          imageSrc="/lovable-uploads/44f2e2f8-d0d0-473f-8361-1bcb6ec929e6.png"
          alt="Stuart Knott"
        />
      </div>
      <div ref={heroRef}>
        <HeroContent />
      </div>
    </section>
  );
};

export default Hero;
