
import React, { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { useInView } from 'react-intersection-observer';
import useTypewriter from '@/hooks/useTypewriter';

const PhilosophySection = () => {
  const { ref, inView, entry } = useInView({
    threshold: [0, 0.25, 0.5, 0.75, 1],
    triggerOnce: false
  });

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [showInitText, setShowInitText] = useState(true);
  const [startMainText, setStartMainText] = useState(false);
  
  const systemText = "Core Values : : Calibrating";
  const title = "Great content is about creating moments that\u00A0matter";
  
  const displayedSystemText = useTypewriter(inView ? systemText : '', 50);
  const displayedMainText = useTypewriter(startMainText ? title : '', 30);

  useEffect(() => {
    if (inView && displayedSystemText === systemText) {
      const timer = setTimeout(() => {
        setShowInitText(false);
        setTimeout(() => setStartMainText(true), 500);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [displayedSystemText, systemText, inView]);

  // Effect for scroll-based transitions
  useEffect(() => {
    const handleScroll = () => {
      if (!entry?.target) return;
      
      const sectionRect = entry.target.getBoundingClientRect();
      const sectionHeight = sectionRect.height;
      const viewportHeight = window.innerHeight;
      const sectionTop = sectionRect.top;
      
      // Calculate progress through the section (0 to 1)
      const progress = Math.min(Math.max(
        (viewportHeight - sectionTop) / (sectionHeight + viewportHeight),
        0
      ), 1);

      // Map progress to active index (0 to 3)
      const newIndex = Math.min(
        Math.floor(progress * 4),
        3
      );
      
      setActiveIndex(newIndex);
    };

    if (inView) {
      window.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial calculation
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, [inView, entry]);

  const principles = [
    'Storytelling that serves strategy',
    'Data that drives decisions',
    'Creativity that converts',
    'Partnerships that push boundaries'
  ];

  return (
    <section ref={ref} className="py-20 md:py-32 relative overflow-hidden bg-white">
      <div className="container-custom relative">
        <div className="max-w-4xl mx-auto px-4 md:px-0">
          <div className="space-y-12 text-center">
            <div className="space-y-4 relative mx-auto">
              <p className="section-heading">
                Philosophy
              </p>

              <div className="text-center mb-6">
                <div className={cn(
                  "font-mono text-[10px] text-black/90 flex items-center justify-center gap-2",
                  displayedSystemText === systemText ? "animate-pulse" : ""
                )}>
                  <span className="w-2 h-px bg-black/90" />
                  <span>{displayedSystemText}</span>
                  <span className={cn(
                    "hidden md:inline-block h-3 w-0.5 bg-black/90 ml-1",
                    displayedSystemText.length === systemText.length ? "animate-pulse" : "animate-blink"
                  )} />
                  <span className="w-2 h-px bg-black/90" />
                </div>
              </div>

              <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold min-h-[80px] max-w-[90%] mx-auto">
                {startMainText && displayedMainText}
                {startMainText && (
                  <span className={cn(
                    "hidden md:inline-block h-6 w-0.5 bg-black ml-1",
                    displayedMainText.length === title.length ? "animate-pulse" : "animate-blink"
                  )}></span>
                )}
              </h2>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-12">
              {principles.map((principle, index) => (
                <div 
                  key={index}
                  className={cn(
                    "relative transition-all duration-1000 mx-auto",
                    inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
                  )}
                  style={{ 
                    transitionDelay: `${index * 400}ms`,
                  }}
                >
                  <div className="relative w-[200px] h-[200px] group">
                    <div className={cn(
                      "absolute inset-0 transition-opacity duration-500",
                      inView && index === activeIndex ? "opacity-100" : "opacity-0"
                    )}>
                      {[...Array(3)].map((_, idx) => (
                        <div
                          key={`inner-segment-${idx}`}
                          className="absolute inset-0"
                          style={{
                            borderRadius: '50%',
                            border: '1px solid black',
                            clipPath: `polygon(50% 50%, 0 0, ${100 * Math.cos((idx + 1) * 2 * Math.PI / 3 - Math.PI/6)}% ${100 * Math.sin((idx + 1) * 2 * Math.PI / 3 - Math.PI/6)}%, ${100 * Math.cos(idx * 2 * Math.PI / 3 - Math.PI/6)}% ${100 * Math.sin(idx * 2 * Math.PI / 3 - Math.PI/6)}%)`,
                            transform: 'scale(1.15)',
                            animation: 'spin 15s linear infinite reverse'
                          }}
                        />
                      ))}
                    </div>

                    <div className={cn(
                      "absolute inset-0 transition-opacity duration-500",
                      inView && index === activeIndex ? "opacity-100" : "opacity-0"
                    )}>
                      {[...Array(4)].map((_, idx) => (
                        <div
                          key={`outer-segment-${idx}`}
                          className="absolute inset-0"
                          style={{
                            borderRadius: '50%',
                            border: '1px solid black',
                            clipPath: `polygon(50% 50%, ${100 * Math.cos(idx * Math.PI / 2)}% ${100 * Math.sin(idx * Math.PI / 2)}%, ${100 * Math.cos((idx + 0.8) * Math.PI / 2)}% ${100 * Math.sin((idx + 0.8) * Math.PI / 2)}%)`,
                            transform: 'scale(1.35)',
                            animation: 'spin 20s linear infinite'
                          }}
                        />
                      ))}
                    </div>

                    <div className={cn(
                      "absolute inset-0 transition-opacity duration-500",
                      inView && index === activeIndex ? "opacity-100" : "opacity-0"
                    )}>
                      {[0, 90, 180, 270].map((angle) => (
                        <div
                          key={`marker-${angle}`}
                          className="absolute"
                          style={{
                            top: '50%',
                            left: '50%',
                            width: '20px',
                            height: '0.5px',
                            backgroundColor: 'black',
                            transform: `rotate(${angle}deg) translateX(${85}px)`,
                            transformOrigin: '0 0'
                          }}
                        >
                          <div className="absolute right-0 w-0.5 h-2.5 -top-1.25 bg-black" />
                        </div>
                      ))}
                    </div>

                    <div className="absolute inset-0 flex items-center justify-center">
                      <div 
                        className={cn(
                          "w-[160px] h-[160px] rounded-full bg-black flex items-center justify-center p-6",
                          "transition-all duration-500",
                          "border border-black",
                          inView ? "scale-100" : "scale-95",
                          index === activeIndex ? "ring-2 ring-black ring-opacity-50" : ""
                        )}
                      >
                        <p className="text-base lg:text-lg font-medium tracking-wide text-white transition-all duration-300">
                          {principle}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <style>{`
        @keyframes blink {
          0%, 100% { opacity: 1; }
          50% { opacity: 0; }
        }
        .animate-blink {
          animation: blink 0.8s infinite;
        }
        @keyframes spin {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
      `}</style>
    </section>
  );
};

export default PhilosophySection;
