import React, { useState } from 'react';
import { Card } from '@/components/ui/card';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  type CarouselApi
} from "@/components/ui/carousel";
import { cn } from "@/lib/utils";
import { Volume2, Play, Pause } from 'lucide-react';
import { Slider } from "@/components/ui/slider";
import { useInView } from 'react-intersection-observer';
import useTypewriter from '@/hooks/useTypewriter';

const videos = [
  {
    id: 1,
    title: "Honda Heritage: S2000 Edition 100",
    videoUrl: "https://www.youtube.com/embed/nrL6S1ciHlg",
    duration: "03:24",
  },
  {
    id: 2,
    title: "Lloyd's of London – City Risk Index 2015-2025",
    videoUrl: "https://www.youtube.com/embed/dJL3WJfRiFI",
  },
  {
    id: 3,
    title: "BT Business – Jenny Radcliffe on Cyber Agility",
    videoUrl: "https://www.youtube.com/embed/T9Ucrrq6mFw",
  },
  {
    id: 8,
    title: "Lloyd's of London – 325 Years of Lloyd's",
    videoUrl: "https://www.youtube.com/embed/d82I3V92vUk",
  },
  {
    id: 5,
    title: "HSBC Global Private Banking – Ben Ryan",
    videoUrl: "https://www.youtube.com/embed/gcIMaDsM_LI",
  },
  {
    id: 6,
    title: "Profile Pensions – Lost & Found: Ernie's Story",
    videoUrl: "https://www.youtube.com/embed/LoRK6-WdoAo",
  },
  {
    id: 7,
    title: "Honda – Race of Remembrance: Type CR-V",
    videoUrl: "https://www.youtube.com/embed/xtmIWbX_ASM",
  },
  {
    id: 9,
    title: "St. James's Place – The Force Multiplier Effect",
    videoUrl: "https://www.youtube.com/embed/6nvGTzIYjZY",
  }
];

const VideoSection = () => {
  const [activeVideo, setActiveVideo] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(75);
  const [api, setApi] = useState<CarouselApi>();

  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const text = "Playback Systems : : Online";
  const displayedText = useTypewriter(inView ? text : '', 30);

  React.useEffect(() => {
    if (!api) {
      return;
    }

    api.on("select", () => {
      const newIndex = api.selectedScrollSnap();
      setActiveVideo(newIndex);
      setIsPlaying(false);
    });
  }, [api]);

  const handlePlayPause = (index: number) => {
    if (activeVideo !== index) {
      api?.scrollTo(index);
      setActiveVideo(index);
    }
    setIsPlaying(!isPlaying);
  };

  const getVideoUrl = (baseUrl: string, isActiveAndPlaying: boolean) => {
    if (!isActiveAndPlaying) return `${baseUrl}?vq=hd720`;
    const params = new URLSearchParams({
      autoplay: '1',
      mute: '0',
      enablejsapi: '1',
      rel: '0',
      controls: '0',
      vq: 'hd720'
    });
    return `${baseUrl}?${params.toString()}`;
  };

  return (
    <section id="videos" className="py-16 md:py-24 bg-black/95">
      <div className="container-custom max-w-5xl">
        <div className="reveal" ref={ref}>
          <p className="section-heading text-white">
            Featured Videos
          </p>

          <div className="text-center mb-6">
            <div className="font-mono text-[10px] text-white/90 flex items-center justify-center gap-2">
              <span className="w-2 h-px bg-white/90" />
              <span>{displayedText}</span>
              <span className={cn(
                "inline-block h-3 w-0.5 bg-white/90 ml-1",
                displayedText.length === text.length ? "animate-pulse" : "animate-blink"
              )} />
              <span className="w-2 h-px bg-white/90" />
            </div>
          </div>

          <div className="mt-6 relative">
            <Carousel 
              opts={{ 
                align: "start",
                loop: true
              }}
              className="w-full"
              setApi={setApi}
            >
              <CarouselContent>
                {videos.map((video, index) => (
                  <CarouselItem key={video.id} className="md:basis-1/2 lg:basis-1/2">
                    <div className="group relative">
                      <div className="absolute top-3 right-3 z-10 flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <div className="text-[10px] font-mono text-white/90 bg-black/50 px-2 py-1 rounded-sm">
                          V_{String(video.id).padStart(2, '0')}
                        </div>
                        {activeVideo === index && isPlaying && (
                          <div className="w-2 h-2 relative">
                            <div className="absolute inset-0 border border-white/30" />
                            <div className="absolute top-0 right-0 w-1 h-1 border-t border-r border-white/60" />
                          </div>
                        )}
                      </div>

                      <Card className={cn(
                        "overflow-hidden bg-black/80 border-0 group relative",
                        "before:absolute before:inset-0 before:z-10 before:bg-[radial-gradient(circle_at_center,_transparent_40%,_rgba(0,0,0,0.4)_100%)]",
                        "after:absolute after:inset-0 after:z-20 after:pointer-events-none",
                        "after:bg-[linear-gradient(to_bottom,rgba(0,0,0,0.2)_0%,transparent_20%,transparent_80%,rgba(0,0,0,0.2)_100%)]"
                      )}>
                        <div className="relative aspect-video bg-black/90">
                          <div className={cn(
                            "absolute inset-0 z-30 pointer-events-none opacity-0 transition-opacity duration-500",
                            activeVideo === index && "opacity-100"
                          )}>
                            <div className="h-full w-full animate-scan bg-scan-lines" />
                          </div>

                          <iframe
                            src={getVideoUrl(video.videoUrl, isPlaying && activeVideo === index)}
                            title={video.title}
                            className={cn(
                              "absolute inset-0 w-full h-full transition-all duration-500 filter",
                              activeVideo === index && isPlaying 
                                ? "grayscale-0" 
                                : "grayscale group-hover:grayscale-0"
                            )}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; microphone"
                            allowFullScreen
                          />

                          <div className={cn(
                            "absolute bottom-0 left-0 h-px bg-white/30 transition-all duration-500",
                            activeVideo === index ? "w-full" : "w-0"
                          )} />
                        </div>

                        <div className="absolute inset-x-0 bottom-0 z-30 p-4 bg-gradient-to-t from-black/80 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <div className="flex items-center space-x-4">
                            <button
                              onClick={() => handlePlayPause(index)}
                              className="text-white/90 hover:text-white transition-colors"
                            >
                              {isPlaying && activeVideo === index ? (
                                <Pause className="w-5 h-5" />
                              ) : (
                                <Play className="w-5 h-5" />
                              )}
                            </button>

                            <div className="flex-1 h-1 bg-white/20 rounded-full overflow-hidden">
                              <div 
                                className={cn(
                                  "h-full bg-white/80 transition-all duration-300",
                                  isPlaying && activeVideo === index ? "animate-progress" : ""
                                )}
                                style={{ width: '45%' }}
                              />
                            </div>

                            <span className="text-xs font-mono text-white/90">
                              {video.duration}
                            </span>

                            <div className="flex items-center space-x-2 group/volume">
                              <Volume2 className="w-5 h-5 text-white/90" />
                              <div className="w-20 opacity-0 group-hover/volume:opacity-100 transition-opacity duration-300">
                                <Slider
                                  value={[volume]}
                                  max={100}
                                  step={1}
                                  className="cursor-pointer"
                                  onValueChange={([value]) => setVolume(value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>

                    <div className="p-3">
                      <h3 className="text-sm font-medium text-white/90 font-mono">
                        {video.title}
                      </h3>
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>

              <CarouselPrevious className="hidden md:flex -left-12 h-8 w-8 border-white/10 bg-black/50 text-white hover:bg-black/80 hover:border-white/20" />
              <CarouselNext className="hidden md:flex -right-12 h-8 w-8 border-white/10 bg-black/50 text-white hover:bg-black/80 hover:border-white/20" />
            </Carousel>

            <div className="mt-6 flex justify-center gap-1">
              {videos.map((_, index) => (
                <div
                  key={index}
                  className={cn(
                    "h-px transition-all duration-300",
                    index === activeVideo ? "w-8 bg-white" : "w-4 bg-white/30"
                  )}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
