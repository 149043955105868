
import { useEffect } from 'react';
import Navigation from '../components/Navigation';
import Hero from '../components/Hero';
import AboutSection from '../components/sections/AboutSection';
import PhilosophySection from '../components/sections/PhilosophySection';
import ExpertiseSection from '../components/sections/ExpertiseSection';
import SuperpowerSection from '../components/sections/SuperpowerSection';
import ProjectsSection from '../components/sections/ProjectsSection';
import VideoSection from '../components/sections/VideoSection';
import TestimonialsSection from '../components/sections/TestimonialsSection';
import AwardsSection from '../components/sections/AwardsSection';
import ClientsSection from '../components/sections/ClientsSection';
import ContactSection from '../components/sections/ContactSection';
import Footer from '../components/Footer';

const Index = () => {
  useEffect(() => {
    const handleReveal = () => {
      const reveals = document.querySelectorAll('.reveal');
      reveals.forEach((element) => {
        const windowHeight = window.innerHeight;
        const elementTop = element.getBoundingClientRect().top;
        const elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          element.classList.add('active');
        }
      });
    };

    window.addEventListener('scroll', handleReveal);
    handleReveal();

    return () => window.removeEventListener('scroll', handleReveal);
  }, []);

  return (
    <div className="min-h-screen">
      <Navigation />
      <Hero />
      <AboutSection />
      <PhilosophySection />
      <ExpertiseSection />
      <SuperpowerSection />
      <ProjectsSection />
      <VideoSection />
      <TestimonialsSection />
      <AwardsSection />
      <ClientsSection />
      <ContactSection />
      <Footer />
    </div>
  );
};

export default Index;
