
import { useState, useEffect, useCallback } from 'react';

const useTypewriter = (text: string, speed: number = 30) => {
  const [displayedText, setDisplayedText] = useState('');
  
  const startTyping = useCallback(() => {
    let currentIndex = 0;
    setDisplayedText(''); // Reset at start
    
    const interval = setInterval(() => {
      currentIndex++;
      setDisplayedText(text.slice(0, currentIndex));
      
      if (currentIndex >= text.length) {
        clearInterval(interval);
      }
    }, speed);

    return () => clearInterval(interval);
  }, [text, speed]);

  useEffect(() => {
    const cleanup = startTyping();
    return cleanup;
  }, [startTyping]);

  return displayedText;
};

export default useTypewriter;
