
import React from 'react';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { cn } from "@/lib/utils";
import { useInView } from 'react-intersection-observer';

interface PrecisionCarouselProps {
  items: Array<{
    icon: React.ElementType;
    title: string;
    description: string;
  }>;
  className?: string;
}

const PrecisionCarousel = ({ items, className }: PrecisionCarouselProps) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <div ref={ref} className={cn("relative", className)}>
      <Carousel opts={{ align: "start" }}>
        <CarouselContent>
          {items.map((item, index) => {
            const Icon = item.icon;
            return (
              <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3">
                <div 
                  className={cn(
                    "relative p-8 group bg-black rounded-sm transition-all duration-500",
                    "transform hover:translate-y-[-2px]",
                    "shadow-[0_0_0_1px_rgba(255,255,255,0.1)]",
                    "hover:shadow-[0_4px_20px_-2px_rgba(0,0,0,0.2),0_0_0_1px_rgba(255,255,255,0.1)]",
                    inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
                  )}
                  style={{ 
                    transitionDelay: `${index * 100}ms`
                  }}
                >
                  {/* Precision corner marker */}
                  <div className="absolute top-0 right-0 w-8 h-8">
                    <div className="absolute top-3 right-0 w-3 h-px bg-white/20" />
                    <div className="absolute top-0 right-3 w-px h-3 bg-white/20" />
                    <div className="text-[8px] absolute top-1 right-4 font-mono text-white/40">
                      {(index + 1).toString().padStart(2, '0')}
                    </div>
                  </div>

                  {/* Icon with subtle highlight effect - centered */}
                  <div className="relative mb-6 flex justify-center group-hover:transform group-hover:scale-105 transition-transform duration-500">
                    <div className="absolute inset-0 bg-blue-500/10 rounded-full blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                    <Icon 
                      className="w-8 h-8 text-white relative z-10"
                      strokeWidth={1}
                    />
                  </div>

                  {/* Content with precise spacing - centered */}
                  <div className="space-y-4 text-center">
                    <h3 className="text-lg font-medium tracking-wide text-white">
                      {item.title}
                    </h3>
                    <p className="text-sm text-white/70 leading-relaxed">
                      {item.description}
                    </p>
                  </div>

                  {/* Accent line */}
                  <div className="absolute bottom-0 left-0 w-full h-px overflow-hidden">
                    <div className={cn(
                      "h-px w-full transform transition-transform duration-500",
                      "bg-gradient-to-r from-transparent via-white/10 to-transparent",
                      "group-hover:via-blue-500/20",
                      "opacity-0 group-hover:opacity-100"
                    )} />
                  </div>
                </div>
              </CarouselItem>
            );
          })}
        </CarouselContent>

        {/* Precision navigation controls */}
        <CarouselPrevious className="absolute -left-12 lg:-left-16 h-8 w-8 border-white/10 hover:border-white/20 hover:bg-white/5 transition-colors duration-300" />
        <CarouselNext className="absolute -right-12 lg:-right-16 h-8 w-8 border-white/10 hover:border-white/20 hover:bg-white/5 transition-colors duration-300" />
      </Carousel>

      {/* Precise progress indicator */}
      <div className="flex justify-center mt-8 gap-1">
        {items.map((_, index) => (
          <div 
            key={index}
            className="w-4 h-px bg-black/20 overflow-hidden"
          >
            <div className="w-full h-full bg-black/40 transform -translate-x-full group-hover:translate-x-0 transition-transform duration-500" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrecisionCarousel;
